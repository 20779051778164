/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
class IntersectionHydrator {
    debug = false;
    hydratedComponents = [];
    mapping = {};
    observer;
    registrations = [];
    registeredOnetrustBlockedComponent = false;
    constructor(registrations, debug = false) {
        if (!window.ReactEnvironmentComponents ||
            !window.ReactEnvironmentComponents.Components) {
            return;
        }
        this.debug = debug;
        this.observer = new IntersectionObserver(this.handleIntersection, {
            threshold: 0,
            rootMargin: '0px',
        });
        this.registrations = registrations;
        this.registerComponents();
        if (window.kentico && window.kentico.pageBuilder) {
            window.setInterval(this.registerComponents, 1000);
        }
        const handle = window.setInterval(() => {
            if (!window.OneTrust ||
                !window.OneTrust.IsAlertBoxClosed() ||
                this.registeredOnetrustBlockedComponent === true) {
                return;
            }
            this.registeredOnetrustBlockedComponent = true;
            this.registerComponents();
            window.clearTimeout(handle);
        }, 60);
    }
    registerComponents = () => {
        // Loop through the components on the window
        for (let index = 0; index < window.ReactEnvironmentComponents.Components.length; index++) {
            const component = window.ReactEnvironmentComponents.Components[index];
            // get the entry point for the component
            const element = document.querySelector(`#${component.id}`);
            if (element === null || !this.observer) {
                continue;
            }
            // Find the registration we have defined so we know wich file to import
            const registrationLookup = this.registrations.find((x) => `Components.${x.name}` === component.name);
            if (!registrationLookup) {
                continue;
            }
            // Create a deep copy
            const registration = JSON.parse(JSON.stringify(registrationLookup));
            // Copy over the path as it's not serialisable
            registration.import = registrationLookup.import;
            // Assign the props to the registation
            registration.props = component.props;
            // Assign the registion to the mapping
            this.mapping[component.id] = registration;
            // Hydrate based on the config
            switch (registration.config.hydrate) {
                default:
                case 'never': {
                    break;
                }
                case 'eager': {
                    if (registration.config.blockedByOnetrust === true &&
                        !window.OneTrust?.IsAlertBoxClosed()) {
                        break;
                    }
                    this.hydrateComponent(registration, component.props, element);
                    break;
                }
                case 'lazy': {
                    if (registration.config.blockedByOnetrust === true &&
                        !window.OneTrust?.IsAlertBoxClosed()) {
                        break;
                    }
                    this.hydrateComponentIdle(registration, component.props, element);
                    break;
                }
                case 'in-view': {
                    if (registration.config.blockedByOnetrust === true &&
                        !window.OneTrust?.IsAlertBoxClosed()) {
                        break;
                    }
                    this.observer.observe(element);
                    break;
                }
                case 'always': {
                    if (registration.config.blockedByOnetrust === true &&
                        !window.OneTrust?.IsAlertBoxClosed()) {
                        break;
                    }
                    console.warn(`Hydrator: "always" has been deprecated in favour of "eager".`);
                    this.hydrateComponent(registration, component.props, element);
                    break;
                }
            }
        }
    };
    handleIntersection = (entries) => {
        for (const entry of entries) {
            if (!entry.isIntersecting) {
                continue;
            }
            const mapping = this.mapping[entry.target.id];
            if (!mapping) {
                continue;
            }
            const hasHydrated = this.hydratedComponents.indexOf(entry.target.id) > -1;
            if (!hasHydrated) {
                this.hydrateComponentIdle(mapping, mapping.props, entry.target);
                this.hydratedComponents.push(entry.target.id);
            }
        }
    };
    hydrateComponent = async (registration, props, container) => {
        this.markStart(registration);
        registration.import().then((e) => {
            ReactDOM.hydrate(React.createElement(e.default, props), container);
            this.markEnd(registration);
        });
    };
    hydrateComponentIdle = async (registration, props, container) => {
        if (typeof window.requestIdleCallback === 'undefined') {
            this.hydrateComponent(registration, props, container);
        }
        window.requestIdleCallback(() => {
            this.hydrateComponent(registration, props, container);
        });
    };
    logVerbose(message, ...optionalParams) {
        if (this.debug) {
            console.log(message, ...optionalParams);
        }
    }
    markStart(registration) {
        if (typeof window.performance !== 'undefined') {
            window.performance.mark(`hydrator-${registration.name}-start`);
        }
    }
    markEnd(registration) {
        if (typeof window.performance !== 'undefined') {
            window.performance.mark(`hydrator-${registration.name}-end`);
            const measurement = window.performance.measure(`hydrator-${registration.name}`, `hydrator-${registration.name}-start`, `hydrator-${registration.name}-end`);
            this.logVerbose(`hydrator-${registration.name} - ${measurement.duration}`, measurement, registration);
        }
    }
}
export default IntersectionHydrator;
